<template>
  <v-menu
    v-model="dialog"
    offset-y
    nudge-bottom="9"
    nudge-left="186"
    :min-width="$vuetify.breakpoint.smAndDown ? '100vw' : ''"
    :content-class="$vuetify.breakpoint.smAndDown ? 'elevation-0 white' : 'white'"
    :close-on-content-click="false"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        icon
        color="secondary"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon
          color="secondary"
          >
          mdi-bell
        </v-icon>

        <div
          style="width: 16px; height: 16px; position: absolute; left: 0; bottom: 0"
          class="primary white--text d-flex justify-center align-center rounded-circle"
          v-if="unreadNotifications"
          >
          {{ unreadNotifications }}
        </div>
      </v-btn>
    </template>

    <v-list
      color="white"
      width="100%"
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 97px)' : '300'"
      >
      <v-subheader class="text--primary body-1"><v-icon small color="primary" class="mr-1">mdi-bell</v-icon> Mis notificaciones</v-subheader>
      <template
        v-for="notification, i in notifications"
        >
        <v-list-item
          class="fill-width py-2"
          :class="i % 2 ? 'white' : 'grey lighten-4'"
          >
          <notification
            :notification="notification"
            @redirect="redirecting = true"
            ></notification>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { Notifications } from '@/graphql/queries/notifications'
import { UnreadNotifications } from '@/graphql/subscriptions/notifications'

import Notification from './Notification'

export default {
  data: () => ({
    notifications: [],
    unreadNotifications: 0,
    dialog: false,
    redirecting: false
  }),

  watch: {
    dialog (val) {
      if (val) {
        this.fetchNotifications ()
      }
    }
  },

  apollo: {
    $subscribe: {
      tags: {
        query: UnreadNotifications,
        variables () {
          return {
            profileId: this.currentUser.selectedProfile.id
          }
        },
        result ({ data }) {
          this.$nextTick( () => {
            this.unreadNotifications = data.unreadNotifications.count
          })
        },
      },
    },
  },

  mounted () {
    this.unreadNotifications = this.currentUser.selectedProfile.unreadNotifications
  },

  methods: {
    fetchNotifications () {
      this.$apollo.query({
        query: Notifications,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.notifications = res.data.notifications
        this.unreadNotifications = 0
      })
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: { Notification }
}
</script>

import gql from 'graphql-tag'

export const Notifications = gql`
  query notifications {
    notifications {
      id
      elapsed
      notificableType
      notificableId
      token
      fromPicture
      fromSlug
      text
      createdAt
    }
  } 
`

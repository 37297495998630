<template>
  <div
    class="fill-width"
    >
    <v-dialog
      width="600"
      v-model="dialog"
      content-class="rounded-0"
      scrollable
      :fullscreen="notification.notificableType == 'Order' && $vuetify.breakpoint.mobile"
      >
      <template
        v-if="dialog"
        >
        <template
          v-if="notification.notificableType == 'Social::Post'"
          >
          <post
            :id="notification.notificableId"
            @close="dialog = false"
            ></post>
        </template>
        <template
          v-if="notification.notificableType == 'Social::Comment'"
          >
          <comment
            :id="notification.notificableId"
            @close="dialog = false"
            ></comment>
        </template>
        <template
          v-if="notification.notificableType == 'Social::Like'"
          >
          <like
            :id="notification.notificableId"
            @close="dialog = false"
            ></like>
        </template>
        <template
          v-if="notification.notificableType == 'Order'"
          >
          <order
            :currentOrder="{ orderToken: notification.token }"
            :checkout="false"
            close
            @close="dialog = false"
            ></order>
        </template>
      </template>
    </v-dialog>
    <v-row
      @click="openDialog"
      class="align-center"
      :style="{ cursor: notification.notificableType ? 'pointer' : '' }"
      >
      <v-col
        cols="2"
        >
        <v-avatar
          @click="redirect"
          class="shrink"
          >
          <v-img
            v-if="notification.fromPicture"
            :src="url + notification.fromPicture"
            ></v-img>
          <v-icon
            v-else
            >
            mdi-account
          </v-icon>
        </v-avatar>
      </v-col>

      <v-col
        cols="7"
        class="pr-0 body-2"
        >
        <span v-text="notification.text"></span>
      </v-col>

      <v-col
        cols="3"
        >
        <div
          class="caption text-right"
          >
          {{ transformTime(notification.elapsed) }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const Order = () => import('@/components/orders/Detail')
const Comment = () => import('./Comment')
const Post = () => import('./Post')
const Like = () => import('./Like')

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  methods: {
    transformTime (seconds) {
      if (seconds < 60) {
        if (seconds == 1) return seconds + ' segundo'
        return seconds + ' segundos'
      } else if (seconds >= 60) {
        var min = Math.floor(seconds / 60)
        if (min == 1) return min + ' minuto'
        if (min < 60) return min + ' minutos'

        var hour = Math.floor(min / 60)
        if (hour == 1) return hour + ' hora'
        if (hour < 24) return hour + ' horas'

        var days = Math.floor(hour / 24)
        if (days == 1) return days + ' día'
        return days + ' días'
      }
    },

    openDialog () {
      if (this.notification.notificableType)
        if (this.notification.notificableType == 'Social::Follow') {
          this.redirect()
        } else {
          this.dialog = true
        }
    },
    
    redirect () {
      this.$emit('redirect')

      this.$nextTick( () => {
        this.$router.push({ name: 'profile', params: { slug: this.notification.fromSlug } })
      })
    }
  },

  computed: {
    ...mapGetters(['url'])
  },

  components: { Comment, Like, Order, Post }
}
</script>
